import React from 'react';
import { Link } from 'react-router-dom';
import './GameBlog.css';

const GameBlog: React.FC = () => {
    return (
        <div className="blog-page">
            <div className="blog-content">
                <header className="blog-header">
                    <h1>Behind the Game: Paul vs Tyson</h1>
                    <Link to="/" className="back-link">← Back to Game</Link>
                </header>

                <section className="blog-section">
                    <h2>Game Development Story</h2>
                    <p>Our team set out to create an authentic boxing experience that captures the excitement of the historic Paul vs Tyson match. The game combines classic arcade mechanics with modern gaming elements.</p>
                </section>

                <section className="blog-section gameplay">
                    <h2>Gameplay Features</h2>
                    <div className="feature-grid">
                        <div className="feature-card">
                            <h3>Flip Flop Power-up</h3>
                            <p>Every 5 points earned grants you a special Flip Flop ability, allowing for mid-air maneuvers to dodge attacks.</p>
                        </div>
                        <div className="feature-card">
                            <h3>Progressive Difficulty</h3>
                            <p>The game's speed increases as you score more points, testing your reflexes and timing.</p>
                        </div>
                        <div className="feature-card">
                            <h3>Character Selection</h3>
                            <p>Choose between Jake Paul's speed or Mike Tyson's power, each with unique attributes.</p>
                        </div>
                        <div className="feature-card">
                            <h3>Score System</h3>
                            <p>Track your high scores and compete with players worldwide on our leaderboard.</p>
                        </div>
                    </div>
                </section>

                <section className="blog-section fight-details">
                    <h2>The Historic Match</h2>
                    <div className="match-stats">
                        <div className="stat-card">
                            <h3>Venue</h3>
                            <p>AT&T Stadium, Arlington, Texas</p>
                        </div>
                        <div className="stat-card">
                            <h3>Date</h3>
                            <p>November 15, 2024</p>
                        </div>
                        <div className="stat-card">
                            <h3>Viewership</h3>
                            <p>60M+ Global Viewers</p>
                        </div>
                        <div className="stat-card">
                            <h3>Result</h3>
                            <p>Jake Paul wins by unanimous decision</p>
                        </div>
                    </div>
                </section>

                <section className="blog-section tips">
                    <h2>Pro Gaming Tips</h2>
                    <ul className="tips-list">
                        <li>Time your jumps carefully to maximize point scoring</li>
                        <li>Save Flip Flops for challenging sequences</li>
                        <li>Watch your opponent's pattern to predict movements</li>
                        <li>Practice timing for perfect mid-air flips</li>
                    </ul>
                </section>

                <section className="blog-section updates">
                    <h2>Latest Updates</h2>
                    <div className="update-list">
                        <div className="update-item">
                            <span className="update-date">March 2024</span>
                            <h3>New Features Added</h3>
                            <p>Enhanced graphics, improved performance, and new animations.</p>
                        </div>
                        <div className="update-item">
                            <span className="update-date">February 2024</span>
                            <h3>Game Launch</h3>
                            <p>Official release of Paul vs Tyson Boxing Game.</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default GameBlog; 