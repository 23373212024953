import React from 'react';
import './Modal.css';

interface ModalProps {
    title: string;
    children: React.ReactNode;
    onAction?: () => void;
    actionText?: string;
}

const Modal: React.FC<ModalProps> = ({ title, children, onAction, actionText }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>{title}</h3>
                {children}
                {onAction && (
                    <button className="modal-action" onClick={onAction}>
                        {actionText || 'Continue'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Modal; 