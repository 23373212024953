import React, { useEffect } from 'react';

interface SEOProps {
    title: string;
    description: string;
    image?: string;
    url?: string;
}

interface MetaTags {
    'description': string;
    'og:title': string;
    'og:description': string;
    'twitter:title': string;
    'twitter:description': string;
    'og:image'?: string;
    'twitter:image'?: string;
    'og:url'?: string;
}

const SEO: React.FC<SEOProps> = ({ title, description, image, url }) => {
    useEffect(() => {
        // Update title
        document.title = title;

        // Update meta tags
        const metaTags: MetaTags = {
            'description': description,
            'og:title': title,
            'og:description': description,
            'twitter:title': title,
            'twitter:description': description,
        };

        if (image) {
            metaTags['og:image'] = image;
            metaTags['twitter:image'] = image;
        }

        if (url) {
            metaTags['og:url'] = url;
        }

        // Update or create meta tags
        Object.entries(metaTags).forEach(([name, content]) => {
            let element = document.querySelector(`meta[name="${name}"]`) ||
                         document.querySelector(`meta[property="${name}"]`);
            
            if (!element) {
                element = document.createElement('meta');
                if (name.startsWith('og:')) {
                    element.setAttribute('property', name);
                } else {
                    element.setAttribute('name', name);
                }
                document.head.appendChild(element);
            }
            
            element.setAttribute('content', content);
        });

        // Cleanup
        return () => {
            document.title = 'Boxing Game';
        };
    }, [title, description, image, url]);

    return null;
};

export default SEO; 