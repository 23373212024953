import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { analytics } from './firebase/config';
import { logEvent } from "firebase/analytics";
import { Metric } from 'web-vitals';

// Function to check if current user is admin/developer
const isDeveloper = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('dev') === 'true';
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Modified to use Firebase Analytics with developer flag
reportWebVitals((metric: Metric) => {
  if (!isDeveloper()) {  // Only log for real users
    logEvent(analytics, 'web_vitals', {
      name: metric.name,
      value: Math.round(metric.value),
      id: metric.id,
      is_developer: false
    });
  }
});
