import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService: React.FC = () => {
    return (
        <div className="policy-page">
            <div className="policy-content">
                <h1>Terms of Service</h1>
                <p>Last updated: March 2024</p>

                <section>
                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing and playing our game, you agree to these terms and conditions.</p>
                    <ul>
                        <li>Users must be 13 years or older</li>
                        <li>Agreement to follow game rules and guidelines</li>
                        <li>Acceptance of privacy policy</li>
                    </ul>
                </section>

                <section>
                    <h2>2. Game Rules and Fair Play</h2>
                    <p>Users must:</p>
                    <ul>
                        <li>Play fairly and not use cheats or exploits</li>
                        <li>Respect other players and game administrators</li>
                        <li>Not manipulate game scores or statistics</li>
                        <li>Report any bugs or technical issues</li>
                    </ul>
                </section>

                <section>
                    <h2>3. Intellectual Property</h2>
                    <p>All content in this game is protected:</p>
                    <ul>
                        <li>Game graphics, code, and design are copyrighted</li>
                        <li>Character likenesses are used under license</li>
                        <li>User-generated content guidelines</li>
                    </ul>
                </section>

                <section>
                    <h2>4. Advertising Content</h2>
                    <p>Our game includes third-party advertisements:</p>
                    <ul>
                        <li>Ads are provided by Google AdSense</li>
                        <li>Ad content follows platform guidelines</li>
                        <li>Users may opt-out of personalized ads</li>
                    </ul>
                </section>

                <section>
                    <h2>5. Limitation of Liability</h2>
                    <p>We strive to provide a stable gaming experience but:</p>
                    <ul>
                        <li>Cannot guarantee uninterrupted service</li>
                        <li>Are not liable for technical issues</li>
                        <li>Reserve the right to modify game features</li>
                    </ul>
                </section>

                <div className="back-link">
                    <Link to="/">← Back to Game</Link>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService; 