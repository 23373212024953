import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="policy-page">
            <div className="policy-content">
                <h1>Privacy Policy</h1>
                <p>Last updated: March 2024</p>
                
                <section>
                    <h2>1. Information We Collect</h2>
                    <p>We collect the following types of information:</p>
                    <ul>
                        <li>Game performance data (scores, achievements, play time)</li>
                        <li>Device information (browser type, operating system)</li>
                        <li>Analytics data to improve game experience</li>
                        <li>Cookies and similar technologies</li>
                    </ul>
                </section>

                <section>
                    <h2>2. How We Use Your Information</h2>
                    <p>Your information is used for:</p>
                    <ul>
                        <li>Improving game performance and user experience</li>
                        <li>Analyzing game statistics and trends</li>
                        <li>Providing personalized gaming experience</li>
                        <li>Maintaining game security and fair play</li>
                    </ul>
                </section>

                <section>
                    <h2>3. Data Security</h2>
                    <p>We protect your data through:</p>
                    <ul>
                        <li>Secure data encryption</li>
                        <li>Regular security audits</li>
                        <li>Limited access to personal information</li>
                        <li>Compliance with data protection regulations</li>
                    </ul>
                </section>

                <section>
                    <h2>4. Advertising</h2>
                    <p>Our game includes advertisements from Google AdSense and other partners. These services may:</p>
                    <ul>
                        <li>Use cookies to serve personalized ads</li>
                        <li>Collect anonymous usage data</li>
                        <li>Follow Google's privacy policies</li>
                    </ul>
                </section>

                <section>
                    <h2>5. Your Rights</h2>
                    <p>You have the right to:</p>
                    <ul>
                        <li>Access your personal data</li>
                        <li>Request data deletion</li>
                        <li>Opt-out of data collection</li>
                        <li>Contact us about privacy concerns</li>
                    </ul>
                </section>

                <div className="back-link">
                    <Link to="/">← Back to Game</Link>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy; 