import React from 'react';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import FightGame from './components/game/FightGame';
import SEO from './components/SEO';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/layout/Footer';
import PrivacyPolicy from './routes/PrivacyPolicy';
import TermsOfService from './routes/TermsOfService';
import GameBlog from './routes/GameBlog';

function ErrorFallback({ error }: FallbackProps) {
  return (
    <div role="alert" className="error-container">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
}

const App: React.FC = () => {
  return (
    <Router>
      <HelmetProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <div className="app">
            <Routes>
              <Route path="/" element={
                <main className="main-content">
                  <SEO 
                    title="Jake Paul vs Mike Tyson Fight Game - Official Boxing Simulator"
                    description="Experience the epic showdown between Jake Paul and Mike Tyson in this official boxing game. Train, fight, and relive the historic Netflix boxing match!"
                  />
                  
                  <main className="main-content">
                    <header className="fight-header">
                      <h1>Jake Paul vs Mike Tyson</h1>
                      <p className="event-details">November 15, 2024 | AT&T Stadium</p>
                      <div className="fight-stats">
                        <div className="stat">60M+ Viewers</div>
                        <div className="stat">8 Rounds</div>
                        <div className="stat">Historic Match</div>
                      </div>
                    </header>

                    <section className="game-section">
                      <FightGame />
                    </section>

                    <section className="fight-info">
                      <div className="info-column">
                        <h2>Fight Highlights</h2>
                        <ul>
                          <li>Jake Paul landed 78 out of 278 punches</li>
                          <li>Mike Tyson connected with 18 of 97 attempts</li>
                          <li>Unanimous decision victory for Paul</li>
                          <li>Judges scored: 80-72, 79-73, 79-73</li>
                        </ul>
                      </div>

                      <div className="info-column">
                        <h2>Game Instructions</h2>
                        <ul>
                          <li>Tap anywhere on the screen to jump</li>
                          <li>Collect points by dodging attacks</li>
                          <li>Every 5 points earns a Flip Flop power-up</li>
                          <li>Tap again mid-air to use Flip Flop</li>
                        </ul>
                      </div>
                    </section>

                    <section className="fighter-profiles">
                      <h2>Fighter Profiles</h2>
                      <div className="profiles-container">
                        <div className="profile jake">
                          <div className="fighter-image">
                            <img 
                              src="/images/jake-full.jpeg" 
                              alt="Jake Paul" 
                              className="profile-img"
                            />
                          </div>
                          <h3>Jake Paul</h3>
                          <div className="stats">
                            <div>Age: 27</div>
                            <div>Record: 9-1-0</div>
                            <div>Style: Orthodox</div>
                          </div>
                        </div>

                        <div className="profile tyson">
                          <div className="fighter-image">
                            <img 
                              src="/images/tyson-full.jpeg" 
                              alt="Mike Tyson" 
                              className="profile-img"
                            />
                          </div>
                          <h3>Mike Tyson</h3>
                          <div className="stats">
                            <div>Age: 58</div>
                            <div>Record: 50-6-0</div>
                            <div>Style: Peek-a-boo</div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <Footer />
                  </main>
                </main>
              } />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/blog" element={<GameBlog />} />
            </Routes>
          </div>
        </ErrorBoundary>
      </HelmetProvider>
    </Router>
  );
};

export default App;
