import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="site-footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h3>About the Game</h3>
                    <p>Experience the epic showdown between Jake Paul and Mike Tyson in this thrilling boxing game.</p>
                </div>
                
                <div className="footer-section">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><Link to="/blog">Game Blog</Link></li>
                        <li><Link to="/privacy">Privacy Policy</Link></li>
                        <li><Link to="/terms">Terms of Service</Link></li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h3>Legal</h3>
                    <p>© 2024 Paul vs Tyson Game. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer; 